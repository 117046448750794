import type { FC } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import {
  organizationConfigVar,
  permissionsStateVar,
  subscriptionStateVar,
  userRoleVar,
} from 'apollo/reactive';
import { UserRole } from 'apollo/generated/globalTypes';
import PrivacyPolicy from 'containers/Support/PrivacyPolicy';
import LegalNotice from 'containers/Support/LegalNotice';
import AssignDiscounts from 'containers/Modals/AssignDiscounts';
import CreateMenuModal from 'containers/Modals/CreateMenu';
import DialogModal from 'containers/Modals/Dialog';
import EmployeeDetailsModal from 'containers/Modals/EmployeeDetails';
import NewEmployeeModal from 'containers/Modals/NewEmployee';
import NewExpenseModal from 'containers/Modals/NewExpense';
import NewOrderModal from 'containers/Modals/NewOrder';
import NewPartnerModal from 'containers/Modals/NewPartner';
import NewProductModal from 'containers/Modals/NewProduct';
import PartnerDetailsModal from 'containers/Modals/PartnerDetails';
import PartnersOrderModal from 'containers/Modals/Partners';
import ProductsOrderModal from 'containers/Modals/Products';
import PurchaseDetailsModal from 'containers/Modals/PurchaseDetails';
import ReactivatePartnerModal from 'containers/Modals/ReactivatePartner';
import RfidDialogModal from 'containers/Modals/RfidDialog';
import SelectCategoriesModal from 'containers/Modals/SelectCategories';
import SelectProvider from 'containers/Modals/SelectProvider';
import UploadImageDialogModal from 'containers/Modals/UploadImageDialog';
import WebcamModal from 'containers/Modals/Webcam';
import Bookings from './Bookings';
import Calendar from './Calendar';
import CategoryDetail from './Category';
import Cashier from './Cashier';
import CashierProducts from './CashierProducts';
import Employee from './Employee';
import Employees from './Employees';
import Expense from './Expense';
import Expenses from './Expenses';
import MetricBillings from './MetricBillings';
import MetricProductsRanking from './MetricProductsRanking';
import Metrics from './Metrics';
import More from './More';
import Menu from './Menu';
import Document from './Document';
import Organization from './Organization';
import OrganizationQuotas from './Organization/Quotas';
import OrganizationDiscounts from './Organization/Discounts';
import OrganizationCreditConcepts from './Organization/CreditConcepts';
import OrganizationProviders from './Organization/Providers';
import OrganizationConfig from './Organization/Configuration';
import OrganizationPermissionsGroups from './Organization/PermissionsGroups';
import OrganizationMenus from './Organization/Menus';
import PartnerFormConfig from './Organization/PartnerFormConfig';
import NewPartnersRequests from './Organization/NewPartnersRequests';
import NewPartnerRequestDetails from './NewPartnerRequestDetails';
import OrganizationProductsByReference from './Organization/ProductsByReference';
import OrganizationStock from './Organization/Stock';
import OrganizationStockHistory from './Organization/StockHistory';
import OrganizationStoreHistory from './Organization/StoreHistory';
import PermissionsGroup from './PermissionsGroup';
import OrganizationDocuments from './Organization/Documents';
import OrganizationCategories from './Organization/Categories';
import OrganizationCashierHistory from './Organization/CashiersHistory';
import OrganizationTransactionsHistory from './Organization/TransactionsHistory';
import Partner from './Partner';
import Partners from './Partners';
import PreviousOrders from './PreviousOrders';
import Product from './Product';
import Products from './Products';
// import NewOrder from './NewOrder';
import ProductTransactions from './ProductTransactions';
import Support from './Support';
import { Container, Switch } from './styles';

const Main: FC = () => {
  const location = useLocation();
  const subscription = useReactiveVar(subscriptionStateVar);
  const userRole = useReactiveVar(userRoleVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const { activateProductReference } = useReactiveVar(organizationConfigVar);

  return (
    <Container>
      <Switch location={location}>
        <Route path="/" element={<Products />} />
        {/* <Route path="/new-order" element={<NewOrder />} /> */}

        <Route path="/organization" element={<Organization />} />
        {employeePermissions.allowShowQuotas && (
          <Route path="/organization/quotas" element={<OrganizationQuotas />} />
        )}
        <Route
          path="/organization/categories"
          element={<OrganizationCategories />}
        />
        {subscription.allowedDiscounts &&
          employeePermissions.allowShowDiscounts && (
            <Route
              path="/organization/discounts"
              element={<OrganizationDiscounts />}
            />
          )}
        {employeePermissions.allowShowCreditsConcept && (
          <Route
            path="/organization/credits-concepts"
            element={<OrganizationCreditConcepts />}
          />
        )}
        {userRole === UserRole.ADMIN && (
          <>
            <Route
              path="/organization/cashier-history"
              element={<OrganizationCashierHistory />}
            />
          </>
        )}

        {employeePermissions.allowShowTransactionsHistory && (
          <Route
            path="/organization/transactions-history"
            element={<OrganizationTransactionsHistory />}
          />
        )}

        {(employeePermissions.allowAdjustProducts ||
          employeePermissions.allowShowAdjustProducts) && (
          <>
            <Route path="/organization/stock" element={<OrganizationStock />} />
            <Route
              path="/organization/stock-history"
              element={<OrganizationStockHistory />}
            />
            <Route
              path="/organization/store-history"
              element={<OrganizationStoreHistory />}
            />
          </>
        )}

        {employeePermissions.allowPartnerRequests && (
          <>
            <Route
              path="/organization/partners-requests"
              element={<NewPartnersRequests />}
            />
            <Route
              path="/organization/partners-requests/:requestId"
              element={<NewPartnerRequestDetails />}
            />
          </>
        )}

        {userRole === UserRole.ADMIN && (
          <>
            {activateProductReference && (
              <Route
                path="/organization/products-by-reference"
                element={<OrganizationProductsByReference />}
              />
            )}

            <Route path="/organization/menus" element={<OrganizationMenus />} />
            <Route
              path="/organization/new-partner-by-link"
              element={<PartnerFormConfig />}
            />
            <Route path="/organization/menus/:menuId" element={<Menu />} />
            <Route
              path="/organization/permissions"
              element={<OrganizationPermissionsGroups />}
            />
            <Route
              path="/organization/permissions/:groupId"
              element={<PermissionsGroup />}
            />
            <Route
              path="/organization/configuration"
              element={<OrganizationConfig />}
            />
          </>
        )}
        {subscription.allowedGenerateFiles &&
          employeePermissions.allowShowContract && (
            <>
              <Route
                path="/organization/documents/:documentId"
                element={<Document />}
              />
              <Route
                path="/organization/documents"
                element={<OrganizationDocuments />}
              />
            </>
          )}

        <Route path="/partners" element={<Partners />} />
        <Route path="/partners/:partnerId" element={<Partner />} />

        <Route path="/products/:productId" element={<Product />} />
        {employeePermissions.allowEditCategories && (
          <Route path="/categories/:categoryId" element={<CategoryDetail />} />
        )}

        {subscription.allowedProductTransactions && (
          <Route
            path="/products/:productId/transactions"
            element={<ProductTransactions />}
          />
        )}

        {subscription.allowedExpenses && (
          <>
            {employeePermissions.allowShowProviders && (
              <Route
                path="/organization/providers"
                element={<OrganizationProviders />}
              />
            )}
            {employeePermissions.allowShowExpenses && (
              <>
                <Route path="/expenses" element={<Expenses />} />
                <Route path="/expenses/:expenseId" element={<Expense />} />
              </>
            )}
          </>
        )}

        {subscription.allowedEmployees && userRole === UserRole.ADMIN && (
          <>
            <Route path="/employees" element={<Employees />} />
            <Route path="/employees/:employeeId" element={<Employee />} />
          </>
        )}

        {subscription.allowedOrders && (
          <>
            <Route path="/orders" element={<Bookings />} />
            <Route path="/orders/previous" element={<PreviousOrders />} />
          </>
        )}

        {subscription.allowedMetrics && employeePermissions.allowShowMetrics && (
          <>
            <Route path="/metrics" element={<Metrics />} />
            <Route path="/metrics/billing" element={<MetricBillings />} />
            <Route
              path="/metrics/products"
              element={<MetricProductsRanking />}
            />
          </>
        )}

        {subscription.allowedCashier && employeePermissions.allowShowCashier && (
          <>
            <Route path="/cashier" element={<Cashier />} />
            <Route path="/cashier/products" element={<CashierProducts />} />
          </>
        )}

        {employeePermissions.allowShowCalendars && (
          <Route path="/calendar" element={<Calendar />} />
        )}

        <Route path="/more" element={<More />} />
        <Route path="/support" element={<Support />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/legal-notice" element={<LegalNotice />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Switch>
      <AssignDiscounts />
      <CreateMenuModal />
      <DialogModal />
      <EmployeeDetailsModal />
      <NewEmployeeModal />
      <NewExpenseModal />
      <NewOrderModal />
      <NewPartnerModal />
      <NewProductModal />
      <PartnerDetailsModal />
      <PartnersOrderModal />
      <ProductsOrderModal />
      <PurchaseDetailsModal />
      <ReactivatePartnerModal />
      <RfidDialogModal />
      <SelectCategoriesModal />
      <SelectProvider />
      <UploadImageDialogModal />
      <WebcamModal />
    </Container>
  );
};

export default Main;
