import { QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_CASHIER_PRODUCTS } from 'apollo/queries/cashiers';
import { normalizeCashierProduct } from 'model/Cashier';
import type {
  GetCashierProductsQuery,
  GetCashierProductsQueryVariables,
} from 'apollo/graphql.types';

const useCashierProducts = ({
  filters,
  fetchPolicy = 'no-cache',
}: {
  filters?: { from?: Date; to?: Date };
  fetchPolicy?: QueryHookOptions<GetCashierProductsQueryVariables>['fetchPolicy'];
} = {}) => {
  const { data, loading, refetch, error } = useQuery<
    GetCashierProductsQuery,
    GetCashierProductsQueryVariables
  >(GET_CASHIER_PRODUCTS, {
    fetchPolicy,
    variables: {
      filters: filters
        ? {
            dates: {
              fromDate: filters.from || null,
              toDate: filters.to || null,
            },
          }
        : null,
    },
  });

  const cashierProducts = useMemo(
    () =>
      data?.cashierProducts
        ? normalizeCashierProduct(data.cashierProducts)
        : {
            items: [],
            stats: {
              totalPurchases: 0,
              totalCosts: 0,
              totalBenefit: 0,
              totalDiscounts: 0,
              newProducts: 0,
            },
          },
    [data],
  );

  return {
    loading,
    cashierProducts,
    refetch,
    error,
  };
};

export default useCashierProducts;
