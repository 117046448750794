import type {
  CashierByPeriodDataFragment,
  CashierPartner as CashierPartnerGraphql,
  CashierProduct as CashierProductGraphql,
  CashierQuotaByPeriodDataFragment,
  CashierProductsByPeriodDataFragment,
  CashierDataFragment,
} from 'apollo/graphql.types';

export type CloseCashierFormFields = Readonly<{
  realCreditsAdded: number;
  totalCreditsAdded: number;
  restRealCredits: number;
  lastRestRealCredits: number;
  totalExpenses: number;
  totalCashier?: number;
  note?: string;
}>;

const normalizeCashierProductItem = (product: CashierProductGraphql) => {
  const totalPurchases = product.totalPurchases
    ? Number(product.totalPurchases.toFixed(2))
    : 0;
  const totalCosts = product.totalCosts
    ? Number(product.totalCosts.toFixed(2))
    : 0;
  const benefit = totalPurchases - totalCosts;

  const consumedQuantity = product.consumedQuantity
    ? Number(product.consumedQuantity.toFixed(2))
    : 0;

  const quantity = product.quantity ? Number(product.quantity.toFixed(2)) : 0;

  return {
    id: product.id || '',
    name: product.name || '',
    referenceCode: product.referenceCode || '',
    totalDiscounts: product.totalDiscounts || 0,
    isNew: product.isNew || false,
    benefit,
    consumedQuantity,
    quantity,
    totalCosts,
    totalPurchases,
  };
};

export const normalizeCashierProduct = (
  products: CashierProductsByPeriodDataFragment,
) => {
  const items = products.items.map(normalizeCashierProductItem);
  const totalPurchases = Number(
    (products.stats.totalPurchases || 0).toFixed(2),
  );
  const totalCosts = Number((products.stats.totalCosts || 0).toFixed(2));
  const totalProductsDiscounts = items.reduce(
    (acc, product) => acc + product.totalDiscounts,
    0,
  );
  const totalDiscounts = Number(
    ((products.stats.totalDiscounts || 0) + totalProductsDiscounts).toFixed(2),
  );

  const stats = {
    totalPurchases,
    totalCosts,
    totalDiscounts,
    totalBenefit: totalPurchases - totalCosts - totalDiscounts,
    newProducts: products.stats.newProducts || 0,
  };

  return {
    items,
    stats,
  };
};

const normalizeCashierPartner = (partner: CashierPartnerGraphql) => ({
  id: partner.id || '',
  fullName: partner.fullName || '',
  memberNum: partner.memberNum || 0,
  isNew: partner.isNew || false,
  totalPurchases: partner.totalPurchases
    ? Number(partner.totalPurchases.toFixed(2))
    : 0,
  totalDiscounts: partner.totalDiscounts
    ? Number(partner.totalDiscounts.toFixed(2))
    : 0,
  addedCredits: partner.addedCredits
    ? Number(partner.addedCredits.toFixed(2))
    : 0,
  products: partner.products
    ? partner.products.map(normalizeCashierProductItem)
    : [],
});

const normalizeCashierQuota = (quota: CashierQuotaByPeriodDataFragment) => ({
  id: quota.id || '',
  name: quota.quotaName || '',
  credits: quota.credits || 0.0,
  partners: (
    (quota.partners || []) as CashierQuotaByPeriodDataFragment['partners']
  ).map((partner) => ({
    id: partner.id || '',
    fullName: partner.fullName || '',
    memberNum: partner.num || '',
    credits: partner.credits || 0.0,
    discountName: partner.discountName || '',
  })),
});

export const normalizeCashierByPeriod = (
  input: CashierByPeriodDataFragment,
) => {
  const partners = input.partners
    ? input.partners.map(normalizeCashierPartner)
    : [];

  const quotas = input.quotas ? input.quotas.map(normalizeCashierQuota) : [];

  const depositedCredits = partners.reduce(
    (acc, partner) => acc + partner.addedCredits,
    0,
  );

  const newPartners = partners.filter((partner) => partner.isNew).length;

  return {
    totalExpenses: Number((input?.totalExpenses || 0.0).toFixed(2)),
    totalRealCredits: Number((input?.totalRealCredits || 0.0).toFixed(2)),
    products: input.products
      ? normalizeCashierProduct(input.products)
      : {
          items: [],
          stats: {
            totalPurchases: 0,
            totalCosts: 0,
            totalBenefit: 0,
            totalDiscounts: 0,
            newProducts: 0,
          },
        },
    partners,
    quotas,
    depositedCredits: Number(depositedCredits.toFixed(2)),
    newPartners,
  };
};

export type CashierByPeriod = ReturnType<typeof normalizeCashierByPeriod>;
export type CashierProduct = ReturnType<typeof normalizeCashierProductItem>;
export type CashierPartner = ReturnType<typeof normalizeCashierPartner>;
export type CashierQuota = ReturnType<typeof normalizeCashierQuota>;

export const normalizeCashier = (input: CashierDataFragment) => {
  return {
    id: input?.id || '',
    note: input?.note || '',
    realCreditsAdded: input?.realCreditsAdded || 0.0,
    totalCreditsAdded: input?.totalCreditsAdded || 0.0,
    restRealCredits: input?.restRealCredits || 0.0,
    totalExpenses: input?.totalExpenses || 0.0,
    lastRestRealCredits: input?.lastRestRealCredits || 0.0,
    typesOfCash: (input?.typeOfCash || []).map((item) => ({
      key: item.key ? Number(item.key) : 0,
      value: item.key ? Number(item.value) : 0,
    })),
    start: (input?.start && new Date(input.start)) || null,
    end: (input?.end && new Date(input.end)) || null,
    openedBy: input.openedBy
      ? {
          id: input.openedBy.id || '',
          fullName: `${input.openedBy.user.firstName} ${input.openedBy.user.lastName}`,
        }
      : null,
    closedBy: input.closedBy
      ? {
          id: input.closedBy.id || '',
          fullName: `${input.closedBy.user.firstName} ${input.closedBy.user.lastName}`,
        }
      : null,
  };
};
export type Cashier = ReturnType<typeof normalizeCashier>;
