import format from 'date-fns/format';
import EsLocale from 'date-fns/locale/es';
import type { CashierByPeriod, CashierQuota, Cashier } from 'model/Cashier';
import { formatDateToTableExtended2 } from '../dates/formatDates';

const formatDate = (date: Date) =>
  format(date, 'dd_MMMM_yy', {
    locale: EsLocale,
  });

export const cashierProductHeaders = [
  { label: 'Nombre', key: 'name' },
  { label: 'Dispensaciones', key: 'purchases' },
  { label: 'Gastos', key: 'costs' },
  { label: 'Descuentos aplicados', key: 'discounts' },
  { label: 'Ganancias', key: 'benefit' },
  { label: 'Cantidad', key: 'consumedQuantity' },
  { label: 'Cantidad restante', key: 'quantity' },
];

export const getCashierProductFilename = ({
  from = new Date(),
  to = new Date(),
}: {
  from?: Date;
  to?: Date;
}) => `reporte_de_productos_${formatDate(from)}_${formatDate(to)}`;

export const getCashierProductData = ({
  products,
  showProductReference,
}: {
  products?: CashierByPeriod['products'];
  showProductReference: boolean;
}) => {
  const data: Array<Record<string, unknown>> = [];
  const items = products?.items || [];
  const stats = products?.stats;
  const benefit = stats?.totalBenefit ? stats.totalBenefit.toFixed(2) : 0.0;

  data.push({
    name: 'Total',
    discounts: `-${stats?.totalDiscounts || 0.0} cr`,
    purchases: `${stats?.totalPurchases || 0.0} cr`,
    costs: `-${stats?.totalCosts || 0.0} cr`,
    benefit: `${benefit} cr`,
  });

  items.forEach((product) => {
    const hasDiscount = product.totalDiscounts > 0;
    const totalBenefit = product.totalPurchases - product.totalCosts;
    const totalSales = hasDiscount
      ? product.totalDiscounts + product.totalPurchases
      : product.totalPurchases || 0;
    const discounts = hasDiscount ? product.totalDiscounts : 0.0;

    data.push({
      name: `${product.name}${
        showProductReference &&
        product.referenceCode &&
        ` #${product.referenceCode}`
      }`,
      quantity: product.quantity || 0.0,
      consumedQuantity: product.consumedQuantity || 0.0,
      purchases: `${totalSales.toFixed(2)} cr`,
      discounts: `-${discounts} cr`,
      costs: `-${product.totalCosts || 0.0} cr`,
      benefit: `${totalBenefit.toFixed(2)} cr`,
    });
  });

  return data;
};

export const cashierPartnerHeaders = [
  { label: 'Numero de socio', key: 'memberNum' },
  { label: 'Nombre', key: 'name' },
  { label: 'Aportado', key: 'purchases' },
  { label: 'Créditos añadidos', key: 'credits' },
];

export const getCashierPartnerFilename = ({
  from = new Date(),
  to = new Date(),
}: {
  from?: Date;
  to?: Date;
}) => `reporte_de_socios_${formatDate(from)}_${formatDate(to)}`;

export const getCashierPartnerData = (
  partners?: CashierByPeriod['partners'],
) => {
  const data: Array<Record<string, unknown>> = [];

  partners?.forEach((partner) => {
    data.push({
      memberNum: partner.memberNum,
      name: partner.fullName,
      purchases: `${partner.totalPurchases} cr`,
      credits: `${partner.addedCredits || 0} cr`,
    });
  });

  return data;
};

export const cashierQuotaHeaders = [
  { label: 'Nombre', key: 'name' },
  { label: 'N° de socios', key: 'partners' },
  { label: 'Ganancias', key: 'credits' },
];

export const getCashierQuotaFilename = ({
  from = new Date(),
  to = new Date(),
}: {
  from?: Date;
  to?: Date;
}) => `reporte_de_quotas_${formatDate(from)}_${formatDate(to)}`;

export const getCashierQuotaData = (quotas?: CashierQuota[]) => {
  const data: Array<Record<string, unknown>> = [];

  quotas?.forEach((quota) => {
    data.push({
      name: quota.name,
      partners: quota.partners.length || 0,
      credits: `${quota.credits || 0} cr`,
    });
  });

  return data;
};

export const cashierHeaders = [
  { label: 'Abierta por', key: 'openedBy' },
  { label: 'Fecha inicio', key: 'startDate' },
  { label: 'Cerrada por', key: 'closedBy' },
  { label: 'Fecha fin', key: 'endDate' },
  { label: 'Total ingresado', key: 'totalCreditsAdded' },
  { label: 'Gastos', key: 'totalExpenses' },
  { label: 'Resto de última caja', key: 'lastRestRealCredits' },
  { label: 'Créditos en caja', key: 'realCreditsAdded' },
  { label: 'Ajuste', key: 'adjust' },
  { label: 'Nota', key: 'note' },
];

export const getCashierFilename = () =>
  `reporte_de_caja_${formatDate(new Date())}`;

export const getCashierData = (cashiers?: Cashier[]) => {
  const data: Array<Record<string, unknown>> = [];

  cashiers?.forEach((cashier) => {
    const realCreditsAdded = cashier?.realCreditsAdded || 0.0;
    const totalCreditsAdded = cashier?.totalCreditsAdded || 0.0;
    const totalExpenses = cashier?.totalExpenses || 0.0;
    const lastRestRealCredits = cashier?.lastRestRealCredits || 0.0;
    const totalCashier =
      totalCreditsAdded + lastRestRealCredits - totalExpenses;
    const adjust = realCreditsAdded - totalCashier;

    data.push({
      openedBy: cashier?.openedBy?.fullName,
      closedBy: cashier?.closedBy?.fullName,
      startDate: formatDateToTableExtended2(cashier?.start),
      endDate: cashier?.end
        ? formatDateToTableExtended2(cashier.end)
        : 'Sigue abierta',
      note: cashier?.note,
      totalCreditsAdded: `${totalCreditsAdded} cr`,
      totalExpenses: `${totalExpenses} cr`,
      lastRestRealCredits: `${lastRestRealCredits} cr`,
      realCreditsAdded: `${realCreditsAdded} cr`,
      adjust: `${adjust} cr`,
    });
  });

  return data;
};
