import { FC, memo, useCallback, useState } from 'react';
import { Formik } from 'formik';
import { subYears } from 'date-fns';
import { useReactiveVar } from '@apollo/client';
import useModal from 'apollo/hooks/useModal';
import { UserGender } from 'apollo/generated/globalTypes';
import { organizationConfigVar, permissionsStateVar } from 'apollo/reactive';
import DatePicker from 'components/Inputs/DatePicker';
import {
  Container,
  Row,
  SubmitButton,
  TextField,
  Select,
  MultipleRow,
} from './styles';
import type { Props } from './types';

const ProfileForm: FC<Props> = ({
  className,
  id = 'new',
  initialValues,
  onClickCredits,
  onClickQuota,
  onOpenHostMemberDetails,
  onSubmit,
}) => {
  const maxBirthDate = subYears(new Date(), 18);
  const { openValidatePin, close } = useModal();
  const organizationConfig = useReactiveVar(organizationConfigVar);
  const employeePermissions = useReactiveVar(permissionsStateVar);
  const [allowEdition, setAllowEdition] = useState(false);
  const formId = `partner_${id}`;

  window.addEventListener('keypress', (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  });

  const handleActivateEdition = useCallback(() => {
    openValidatePin({
      onCorrectValidation: () => {
        setAllowEdition(true);
        close();
      },
    });
  }, [openValidatePin, close]);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      <Container
        $isSubscribed={!!initialValues.quotaName}
        className={className}
      >
        <MultipleRow>
          <TextField
            id={`${formId}_memberNum`}
            label="Nº de socio"
            name="memberNum"
            readOnly
          />
          <TextField
            id={`${formId}_monthConsume`}
            label="Consumo mensual"
            name="monthConsume"
            readOnly
          />
          <TextField
            name="hostMemberNum"
            label="Nº de padrino"
            id={`${formId}_hostMemberNum`}
            icon="arrowRight"
            iconSize={8}
            onClick={onOpenHostMemberDetails}
            readOnly
          />
          <TextField
            id={`${formId}_credits`}
            label="Créditos"
            name="credits"
            icon="arrowRight"
            iconSize={8}
            onClick={onClickCredits}
            readOnly
          />
          <TextField
            id={`${formId}_quotaName`}
            label="Cuota"
            name="quotaName"
            icon="arrowRight"
            iconSize={8}
            onClick={onClickQuota}
            readOnly
          />
        </MultipleRow>
        {allowEdition && (
          <>
            <Row>
              <TextField
                name="firstName"
                label="Nombre"
                id={`${formId}_firstName`}
              />
              <TextField
                name="lastName"
                label="Apellidos"
                id={`${formId}_lastName`}
              />
            </Row>
            <Row>
              <TextField
                name="document"
                label="DNI"
                id={`${formId}_document`}
              />
              <TextField
                name="address"
                label="Domicilio"
                id={`${formId}_address`}
              />
            </Row>
            <Row>
              <TextField
                id={`${formId}_email`}
                label="Email"
                name="email"
                type="email"
              />
              <TextField
                name="phoneNumber"
                label="Nº de móvil"
                id={`${formId}_phoneNumber`}
              />
            </Row>
            <Row>
              <Select
                name="usage"
                label="¿Lúdico o terapéutico?"
                id={`${formId}_usage`}
                options={[
                  {
                    value: 'PLAYFUL',
                    label: 'Lúdico',
                  },
                  {
                    value: 'THERAPEUTIC',
                    label: 'Terapéutico',
                  },
                ]}
              />
              <TextField
                type="number"
                name="maxConsumeMonth"
                label="Consumo máximo"
                id={`${formId}_maxConsumeMonth`}
              />
            </Row>
            <Row>
              <Select
                name="gender"
                label="Genero"
                id={`${formId}_gender`}
                options={[
                  {
                    value: UserGender.MALE,
                    label: 'Hombre',
                  },
                  {
                    value: UserGender.FEMALE,
                    label: 'Mujer',
                  },
                  {
                    value: UserGender.OTHER,
                    label: 'No especificado',
                  },
                ]}
              />
              <DatePicker
                label="Fecha de nacimiento"
                name="birthDate"
                maxDate={maxBirthDate}
                id={`${formId}_birthDate`}
              />
            </Row>
            {organizationConfig.activateRfidReader && (
              <Row>
                <TextField
                  name="rfidCode"
                  label="Código RFID"
                  id={`${formId}_rfidCode`}
                  icon="rfid"
                  iconSize={22}
                  onLocalChange={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                />
              </Row>
            )}
          </>
        )}
        {employeePermissions.allowEditPartners && (
          <>
            {allowEdition && <SubmitButton type="submit">Guardar</SubmitButton>}
            {!allowEdition && (
              <SubmitButton
                variant="light"
                type="button"
                onClick={handleActivateEdition}
              >
                Editar
              </SubmitButton>
            )}
          </>
        )}
      </Container>
    </Formik>
  );
};

export default memo(ProfileForm);
